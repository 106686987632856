import React from "react";
import Small1Png from "../../../Assets/Home/detailSmall1.png";
import Small2Png from "../../../Assets/Home/detailSmall2.png";
import Small3Png from "../../../Assets/Home/detailSmall3.png";
import Big1Png from "../../../Assets/Home/detailBig1.png";
import Big2Png from "../../../Assets/Home/detailBig2.png";
import Big3Png from "../../../Assets/Home/detailBig3.png";
import Detail from "./Detail";
import "../../../Css/Home/Details.css";
import { useWindowSize } from "@uidotdev/usehooks";

const Details = () => {
  const size = useWindowSize();
  if (size.width && size.width > 750)
    return (
      <div className="details">
        <div>
          <img src={Big1Png} alt="Eat with confidence" />
          <Detail
            header="Eat with confidence"
            img={Small1Png}
            imgText="Scan Feature"
            desc="Navigate your meals with ease and assurance. With EnzymeAI's precise food compatibility ratings, you'll instantly know whether a dish aligns with your dietary goals and restrictions, including allergies. Now, you can savor each bite with the peace of mind that your dietary needs are well-catered for."
          />
        </div>
        <div>
          <Detail
            header="Advanced AI Technology"
            img={Small2Png}
            imgText="Machine Learning"
            desc="Unlock the power of artificial intelligence with EnzymeAI. Our app employs cutting-edge AI algorithms to analyze your health data, dietary preferences, and lifestyle factors. This enables us to provide personalized recommendations and insights that are not only tailored to your unique needs but also evolve with you. Experience the future of dietary management and live a healthier life with EnzymeAI."
          />
          <img src={Big2Png} alt="Advanced AI Technology" />
        </div>
        <div>
          <img src={Big3Png} alt="Family Analytics" />
          <Detail
            header="Family Analytics"
            img={Small3Png}
            imgText="Family Account"
            desc="Manage your entire family's nutritional needs in one place with EnzymeAI. Our app enables you to create individual profiles for each family member, keeping track of their unique dietary needs and preferences. From identifying potential allergens to recommending balanced meals, EnzymeAI simplifies meal planning for the whole family. Stay informed, stay safe, and enjoy a healthier lifestyle together with EnzymeAI."
          />
        </div>
      </div>
    );
  else
    return (
      <div className="mobileDetails">
        <Detail
          header="Eat with confidence"
          img={Small1Png}
          imgText="Scan Feature"
          desc="Navigate your meals with ease and assurance. With EnzymeAI's precise food compatibility ratings, you'll instantly know whether a dish aligns with your dietary goals and restrictions, including allergies. Now, you can savor each bite with the peace of mind that your dietary needs are well-catered for."
        />
        <Detail
          header="Advanced AI Technology"
          img={Small2Png}
          imgText="Machine Learning"
          desc="Unlock the power of artificial intelligence with EnzymeAI. Our app employs cutting-edge AI algorithms to analyze your health data, dietary preferences, and lifestyle factors. This enables us to provide personalized recommendations and insights that are not only tailored to your unique needs but also evolve with you. Experience the future of dietary management and live a healthier life with EnzymeAI."
        />
        <Detail
          header="Family Analytics"
          img={Small3Png}
          imgText="Family Account"
          desc="Manage your entire family's nutritional needs in one place with EnzymeAI. Our app enables you to create individual profiles for each family member, keeping track of their unique dietary needs and preferences. From identifying potential allergens to recommending balanced meals, EnzymeAI simplifies meal planning for the whole family. Stay informed, stay safe, and enjoy a healthier lifestyle together with EnzymeAI."
        />
      </div>
    );
};

export default Details;
