import React from "react";
import "../../Css/Home/Testimonial.css";
import LogoPng from "../../Assets/logo.png";
import Person from "../../Assets/Home/testimonialPerson.png";
import { useWindowSize } from "@uidotdev/usehooks";

const Testimonial = () => {
  const size = useWindowSize();
  if (size.width && size.width > 750)
    return (
      <div className="testimonial">
        <div className="top">
          <div className="quote">“What I am most excited for is the Diet and Recipe-Modification based on my dietary requirements and preset diet choice!”</div>
          <img src={Person} alt="Person" />
          <div className="info">
            K. Youth
            <br />
            Stock Trader / Tech Industry
          </div>
        </div>
        <img src={LogoPng} alt="Logo" />
      </div>
    );
  else
    return (
      <div className="mobileTestimonial">
        <div className="top">“What I am most excited for is the Diet and Recipe-Modification based on my dietary requirements and preset diet choice!”</div>
        <img src={Person} alt="Person" />
        <div className="info">
          K. Youth
          <br />
          Stock Trader / Tech Industry
        </div>
      </div>
    );
};

export default Testimonial;
