import React from "react";
import Body from "./Body";
import "../../Css/Faq/Faq.css";

const Faq = () => {
  return (
    <div className="faq">
      <Body />
    </div>
  );
};

export default Faq;
