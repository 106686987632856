import React from "react";
import "../../Css/Tos/Tos.css";

const Tos = () => {
  return (
    <div className="tos">
      <div className="title">Terms of Service</div>
      <div className="desc">Can be continuously updated without notice.</div>
      <div className="texts">BEING WORKED ON | UNDER CONSTRUCTION</div>
      <div className="updated">Last update: June 17th, 2024</div>
    </div>
  );
};

export default Tos;
