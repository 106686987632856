import React, { Dispatch, SetStateAction } from "react";
import BlogExpanded from "./BlogExpanded";
import removeMarkdown from "markdown-to-text";

interface Props {
  title: string;
  desc: string;
  author: string;
  authorUsername: string;
  authorImage: string;
  uploadDate: number;
  setExpand: Dispatch<SetStateAction<JSX.Element | undefined>>;
}

const IndividualBlog = (props: Props) => {
  let desc = removeMarkdown(props.desc.replaceAll("\\", ""));
  return (
    <div className="individualBlog">
      <div>
        <div className="top">
          <div className="date">{new Date(props.uploadDate).toDateString()}</div>
          <img src={props.authorImage} alt="Author" />
        </div>
        <div className="title">{props.title}</div>
        <div className="desc">
          {desc.slice(0, 330)}
          {desc.length > 330 ? "..." : ""}
        </div>
      </div>
      <button onClick={() => props.setExpand(<BlogExpanded {...props} />)}>Read More</button>
    </div>
  );
};

export default IndividualBlog;
