import React from "react";
import "../../Css/Mission/TopSection.css";

const TopSection = () => {
  return (
    <div className="top">
      <div className="head">OUR MISSION</div>
      <div className="subHead">EnzymeAI allows the world to consume food safely.</div>
      <div className="desc">
        EnzymeAI’s <span>free</span> and accessible features provides the <span>everyday</span> person all the necessary tools and information to make <span>safe</span> and{" "}
        <span>tasty</span> decisions daily.
      </div>
      <div className="shading" style={{ left: -170, top: -103 }} />
      <div className="shading" style={{ left: 56, top: 40 }} />
      <div className="shading" style={{ right: -283, top: -142 }} />
      <div className="shading1" style={{ top: 9 }} />
      <div className="shading2" style={{ top: 182 }} />
    </div>
  );
};

export default TopSection;
