import React from "react";

interface Props {
  img: string;
  imgText: string;
  header: string;
  desc: string;
}

const Detail = (props: Props) => {
  return (
    <div className="detail">
      <div className="icon">
        <img src={props.img} alt={props.imgText} />
        <span>{props.imgText}</span>
      </div>
      <div className="header">{props.header}</div>
      <div className="desc">{props.desc}</div>
    </div>
  );
};

export default Detail;
