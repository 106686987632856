import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Layout from "./Layout/Layout";
import Home from "./Home/Home";
import Contact from "./Contact/Contact";
import NotFound from "./NotFound/NotFound";
import Blog from "./Blog/Blog";
import Faq from "./Faq/Faq";
import Mission from "./Mission/Mission";
import Tos from "./Tos/Tos";
import Security from "./Security/Security";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/tos" element={<Tos />} />
          <Route path="/security" element={<Security />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
