import React, { Dispatch, SetStateAction } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ArrowPng from "../../../Assets/Blog/arrow.png";
import "../../../Css/Blog/BlogExpanded.css";

interface Props {
  title: string;
  desc: string;
  author: string;
  authorUsername: string;
  authorImage: string;
  uploadDate: number;
  setExpand: Dispatch<SetStateAction<JSX.Element | undefined>>;
}

const BlogExpanded = (props: Props) => {
  return (
    <div className="blogExpanded">
      <div onClick={() => props.setExpand(undefined)} className="back">
        <img src={ArrowPng} alt="Arrow" />
        <div>Back to blog</div>
      </div>
      <div className="date">{new Date(props.uploadDate).toDateString()}</div>
      <div className="title">{props.title}</div>
      <div className="posted">
        <div className="title">Posted by</div>
        <div className="info">
          <img src={props.authorImage} alt="Author" />
          <div>
            <div className="name">{props.author}</div>
            <div className="username">@{props.authorUsername}</div>
          </div>
        </div>
      </div>
      <Markdown className="markdown" remarkPlugins={[remarkGfm]}>
        {props.desc}
      </Markdown>
      <div className="shading" style={{ top: 176.68, left: 61.05 }} />
      <div className="shading" style={{ top: 40.68, right: -98.95 }} />
    </div>
  );
};

export default BlogExpanded;
