import React, { useState } from "react";
import "../../Css/Blog/Blog.css";
import Body from "./Body";

const Blog = () => {
  const [expand, setExpand] = useState<JSX.Element | undefined>();
  return <div className="blog">{expand ? expand : <Body setExpand={setExpand} />}</div>;
};

export default Blog;
