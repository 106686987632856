import React from "react";
import "../../../Css/Home/Features.css";
import FeaturesCollection from "./FeaturesCollection";
import { useWindowSize } from "@uidotdev/usehooks";
import PrimaryFeature from "./PrimaryFeature";
import icon1 from "../../../Assets/Home/featureicon1.png";
import icon2 from "../../../Assets/Home/featureicon2.png";
import icon3 from "../../../Assets/Home/featureicon3.png";
import enzo from "../../../Assets/Home/enzo.png";

const Features = () => {
  const size = useWindowSize();
  if (size.width && size.width > 750)
    return (
      <div className="features">
        <div className="desc">
          <pre>What's in EnzymeAI? </pre> All the tools you need for your family's health and safety.
        </div>
        <FeaturesCollection />
      </div>
    );
  else
    return (
      <div className="mobileFeatures">
        <div className="features">
          <PrimaryFeature
            color="blue"
            img={icon2}
            header="Advanced Food Scanning"
            desc="Instantly obtain detailed and exact nutritional data by scanning your food's barcode. With your allergy profile saved, the app also alerts you if the scanned item is safe to consume and if you can eat it based on your diet. Check if your kids/family can eat it and who in the family can!"
          />
          <PrimaryFeature
            color="pink"
            img={icon1}
            header="Profiles & Allergies"
            desc="Create your profile and record your food allergies, sensitivities, or preferences. The app takes care of the rest, ensuring every food/recipe can be consumed by you and your family utilizing a family account."
          />
          <PrimaryFeature
            color="green"
            img={icon3}
            header="Recipes & Sharing"
            desc="Customize and share recipes using our AI, tailored to your dietary needs and preferences. Our machine learning algorithm ensures each shared recipe has the ability to adapt for its recipient."
          />
        </div>
        <div className="subFeatures">
          <div>Data Protection</div>
          <div>Vast Ammounts of Data</div>
          <div>Artificial Intelligence</div>
          <div>Powerful Algorithms</div>
          <div>Diets</div>
          <div>Future Features</div>
        </div>
        <div className="ai">
          <div>
            OUR AI:
            <br /> Enzo the Enzyme
          </div>
          <img src={enzo} alt="Enzo the Enzyme" />
        </div>
      </div>
    );
};

export default Features;
