import React, { useState } from "react";
import "../../Css/Contact/Contact.css";
import { Link } from "react-router-dom";
import DiscordPng from "../../Assets/Contact/discord.png";
import PearPng from "../../Assets/Contact/pear.png";
import { API_URL } from "../../utils/contants";
const Contact = () => {
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [statusColor, setStatusColor] = useState<string>("");
  let sent = false;
  const submit = async () => {
    if (sent) return;
    sent = true;
    let res = await fetch(`${API_URL}/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        message,
      }),
    });
    const data = await res.json();
    if (data.status == 200) setStatusColor("lightgreen");
    else setStatusColor("red");
    setStatus(data.message);
  };
  return (
    <div className="contact">
      <img src={PearPng} alt="Pear" />
      <div className="container">
        <div className="top">
          <div className="header">Talk to our Support team</div>
          <div className="desc">We'll help you out!</div>
        </div>
        <div className="box">
          <div className="status" style={{ color: statusColor }}>
            {status}
          </div>
          <label htmlFor="email">Email / Company Email</label>
          <input onChange={(t) => setEmail(t.target.value)} id="email" type="text" />
          <label style={{ marginTop: 55 }} htmlFor="message">
            How Can We Help You?
          </label>
          <textarea onChange={(t) => setMessage(t.target.value)} id="message"></textarea>
          <div className="bottom">
            <button onClick={submit} className={email.length > 0 && message.length > 0 ? "submitA" : "submitP"}>
              Submit
            </button>
            <div className="discord">
              <div className="desc">Get Instant Support by joining our</div>
              <Link target="_" className="link" to="https://discord.gg/VY2Xh73MkC">
                <img src={DiscordPng} alt="Discord" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
