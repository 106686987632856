import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Nav from "./Nav";
import Footer from "./Footer/Footer";
import "../../Css/Layout/Layout.css";
import FreeOfferPopup from "./Popups/FreeOfferPopup";
import SendPopup from "./Popups/SendPopup";

const Layout = () => {
  const [isPopup, setIsPopup] = useState<boolean>(false);
  const [popupElem, setPopupElem] = useState<JSX.Element>();
  const params = new URLSearchParams(useLocation().search);
  const freepopup = params.get("freeOfferPopup");
  const sendPopup = params.get("sendPopup");
  useEffect(() => {
    if (freepopup == "true") setIsPopup(true), setPopupElem(<FreeOfferPopup />);
    else if (sendPopup == "true") setIsPopup(true), setPopupElem(<SendPopup />);
    else setIsPopup(false), setPopupElem(<></>);
  }, [freepopup, sendPopup]);
  return (
    <>
      <div className={`layout ${isPopup ? "popupLayout" : ""}`}>
        <Nav />
        <Outlet />
        <Footer />
      </div>
      {popupElem}
    </>
  );
};

export default Layout;
