import React from "react";

interface Props {
  header: string;
  desc: string;
  color: "blue" | "green" | "pink";
}

const SecondaryFeature = (props: Props) => {
  return (
    <div className={`secondaryfeaturebox ${props.color}`}>
      <div className="container">
        <div className="header">{props.header}</div>
        <div className="desc">{props.desc}</div>
      </div>
    </div>
  );
};

export default SecondaryFeature;
