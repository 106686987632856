import React, { useState } from "react";
import ClosePng from "../../../Assets/FreeOfferPopup/close.png";
import Discord from "../../../Assets/Contact/discord.png";
import "../../../Css/Layout/Popups/FreeOfferPopup.css";
import { Link, useLocation } from "react-router-dom";
import { API_URL } from "../../../utils/contants";

const FreeOfferPopup = () => {
  const location = useLocation();
  const path = location.pathname;
  const [email, setEmail] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [statusColor, setStatusColor] = useState<string>("");
  let sent = false;
  const submit = async () => {
    if (sent) return;
    sent = true;
    let res = await fetch(`${API_URL}/freeoffer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    });
    const data = await res.json();
    if (data.status == 200) setStatusColor("lightgreen");
    else setStatusColor("red");
    setStatus(data.message);
  };
  return (
    <div className="freeofferpopup">
      <Link className="close" to={path}>
        <img src={ClosePng} alt="Close" />
      </Link>
      <div className="header">Free Offer!</div>
      <div className="desc">
        Subscribe to receive free EnzymeAI Premium on launch for 3 months free <span>NO CREDIT CARD REQUIRED!</span>
      </div>
      <div className="input">
        <input onChange={(t) => setEmail(t.currentTarget.value)} placeholder="Email Address" type="text" />
        <div onClick={submit}>SUBSCRIBE</div>
      </div>
      <div className="learnmore">Learn more about this offer</div>
      <div style={{ color: statusColor }} className="status">
        {status}
      </div>
      <div className="discord">
        <div>Want To Join Our Discord For a Interactive Experience?</div>
        <Link target="_" to="https://discord.gg/VY2Xh73MkC" className="link">
          <img src={Discord} alt="Discord" />
        </Link>
      </div>
    </div>
  );
};

export default FreeOfferPopup;
