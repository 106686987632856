import React from "react";
import LogoPng from "../../../Assets/logo.png";
import FacebookPng from "../../../Assets/Footer/facebook.png";
import InstagramPng from "../../../Assets/Footer/instagram.png";
import TwitterPng from "../../../Assets/Footer/twitter.png";
import DiscordPng from "../../../Assets/Footer/discord.png";
import { Link } from "react-router-dom";

const LeftSection = () => {
  return (
    <div className="leftSection">
      <img src={LogoPng} alt="Logo" />
      <div className="desc">
        EnzymeAI leverages AI for tailored dietary management. We offer personalized nutrition advice, recipe adjustments, and robust data protection for a healthier, smarter food
        experience.
      </div>
      <div className="socials">
        <Link target="_" to="https://www.facebook.com/profile.php?id=61559852576122" className="icons">
          <img src={FacebookPng} alt="Facebook" />
        </Link>
        <Link target="_" to="https://twitter.com/EnzymeAI" className="icons">
          <img src={TwitterPng} alt="Twitter" />
        </Link>
        <Link target="_" to="https://www.instagram.com/enzymeai_mobile" className="icons">
          <img src={InstagramPng} alt="Instagram" />
        </Link>
        <Link target="_" to="https://discord.gg/VY2Xh73MkC" className="icons">
          <img src={DiscordPng} alt="Discord" />
        </Link>
      </div>
    </div>
  );
};

export default LeftSection;
