import React, { useEffect, useState } from "react";
import "../../../Css/Faq/Faqs.css";
import FaqData from "../../../Database/Faqs.json";
import IndividualFaq from "./IndividualFaq";

const Faqs = () => {
  const [faqArray, setFaqArray] = useState<Array<JSX.Element>>();
  useEffect(() => {
    let tempArr: Array<JSX.Element> = [];
    for (let i = 0; i < FaqData.length; i++) {
      tempArr.push(<IndividualFaq key={i} lastFaq={i == FaqData.length - 1} question={FaqData[i].question} answer={FaqData[i].answer} />);
    }
    setFaqArray(tempArr);
  }, []);
  return <div className="faqs">{faqArray}</div>;
};

export default Faqs;
