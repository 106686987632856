import React, { useState } from "react";
import SendPng from "../../../Assets/Footer/send.png";
import { API_URL } from "../../../utils/contants";

const RightSection = () => {
  const [email, setEmail] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [statusColor, setStatusColor] = useState<string>("");
  let sent = false;
  const submit = async () => {
    if (sent) return;
    sent = true;
    let res = await fetch(`${API_URL}/newsletter`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    });
    const data = await res.json();
    if (data.status == 200) setStatusColor("lightgreen");
    else setStatusColor("red");
    setStatus(data.message);
  };
  return (
    <div className="rightSection">
      <div className="header">Newsletter</div>
      <div className="input">
        <input onChange={(t) => setEmail(t.target.value)} type="text" placeholder="Email" />
        <img onClick={submit} src={SendPng} alt="Send" />
      </div>
      <div style={{ color: statusColor }} className="status">
        {status}
      </div>
    </div>
  );
};

export default RightSection;
