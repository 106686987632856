import React from "react";
import "../../Css/Mission/Bottom.css";
import { Link } from "react-router-dom";

const Bottom = () => {
  return (
    <div className="bottom">
      <div>Join us and help shape the future of nutrition.</div>
      <Link className="button" to="/mission?sendPopup=true">
        UNLOCK YOUR FREE OFFER
      </Link>
    </div>
  );
};

export default Bottom;
