import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

interface Props {
  header: string;
  links: Array<Array<string>>;
}

const FooterLinks = (props: Props) => {
  const [links, setLinks] = useState<Array<JSX.Element>>();
  //set links
  useEffect(() => {
    const linksArray: Array<JSX.Element> = [];
    for (let i = 0; i < props.links.length; i++) {
      linksArray.push(
        <Link key={i} to={props.links[i][1]} className="link">
          {props.links[i][0]}
        </Link>
      );
    }
    setLinks(linksArray);
  }, [props]);

  return (
    <div className="links">
      <div className="header">{props.header}</div>
      <div className="linkslist">{links}</div>
    </div>
  );
};

export default FooterLinks;
