import React, { useState } from "react";
import "../../../Css/Layout/Popups/SendPopup.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ClosePng from "../../../Assets/FreeOfferPopup/close.png";
import Discord from "../../../Assets/Contact/discord.png";
import { API_URL } from "../../../utils/contants";

const SendPopup = () => {
  const location = useLocation();
  const nav = useNavigate();

  const path = location.pathname;
  const [message, setMessage] = useState<string>("");
  let sent = false;
  const submit = async () => {
    if (sent) return;
    sent = true;
    let res = await fetch(`${API_URL}/faq`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message,
      }),
    });
    nav(path);
  };
  return (
    <div className="sendpopup">
      <Link className="close" to={path}>
        <img src={ClosePng} alt="Close" />
      </Link>
      <div className="header">Send us a message!</div>
      <div className="desc">What did we miss?</div>
      <textarea onChange={(t) => setMessage(t.target.value)} />
      <div className="bottom">
        <button className={message.length > 0 ? "submitA" : "submitP"} onClick={submit}>
          Send
        </button>
        <div>
          <div>
            Your question has most likely already been answered! Join our discord below for <span>free!</span>
          </div>
          <Link target="_" to="https://discord.gg/VY2Xh73MkC" className="link">
            <img src={Discord} alt="Discord" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SendPopup;
