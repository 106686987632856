import React from "react";
import "../../Css/Home/TopSection.css";
import LinePng from "../../Assets/Home/line.png";
import { useWindowSize } from "@uidotdev/usehooks";
import PhonePng from "../../Assets/Home/phone.png";
import { Link, useLocation } from "react-router-dom";

const TopSection = () => {
  const location = useLocation();
  let path = location.pathname;
  const size = useWindowSize();
  if (size.width && size.width > 750)
    return (
      <div className="top">
        <div className="shading" />
        <div className="head">Think Food Freedom, Think EnzymeAI</div>
        <img src={LinePng} alt="line" />
        <div className="desc">Navigating Your Nutrition with Smart AI Technology</div>
        <Link className="freeoffer" to={`${path}?freeOfferPopup=true`}>
          UNLOCK YOUR FREE OFFER
        </Link>
        <div className="freeofferdesc">
          30 -Day Free Premium Experience <span style={{ textDecorationLine: "underline" }}>No Card Information</span> Required
        </div>
      </div>
    );
  else
    return (
      <div className="mobileTop">
        <div className="prehead">BETA-UN RELEASED</div>
        <div className="head">Think Food Freedom, Think EnzymeAI</div>
        <div className="desc">Your ultimate companion for smart eating, allergy management, recipe creation and upgrading, and family meal planning!</div>
        <Link to={`${path}?freeOfferPopup=true`} className="freeoffer">
          Free Offer
        </Link>
        <div className="subhead">What's in EnzymeAI?</div>
        <img src={PhonePng} alt="Phone" />
      </div>
    );
};

export default TopSection;
