import React from "react";
import "../../Css/Home/Home.css";
import Features from "./Features/Features";
import Details from "./Details/Details";
import Testimonial from "./Testimonial";
import TopSection from "./TopSection";

const Home = () => {
  return (
    <div className="home">
      <TopSection />
      <Features />
      <Details />
      <Testimonial />
    </div>
  );
};

export default Home;
