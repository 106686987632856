import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../Css/Layout/Nav.css";
import aiPng from "../../Assets/Nav/ai.png";
import aiDarkPng from "../../Assets/Nav/aiDark.png";
import missionPng from "../../Assets/Nav/mission.png";
import missionDarkPng from "../../Assets/Nav/missionDark.png";
import securityPng from "../../Assets/Nav/security.png";
import securityDarkPng from "../../Assets/Nav/securityDark.png";
import teamPng from "../../Assets/Nav/team.png";
import teamDarkPng from "../../Assets/Nav/teamDark.png";
import tosPng from "../../Assets/Nav/tos.png";
import tosDarkPng from "../../Assets/Nav/tosDark.png";
import crossPng from "../../Assets/Nav/cross.png";
import { useWindowSize } from "@uidotdev/usehooks";
import logo from "../../Assets/logo.png";
import bar from "../../Assets/Nav/bars.png";

const Nav = () => {
  const size = useWindowSize();
  const [mobileP, setMobileP] = useState<boolean>(false);

  const [aboutPopup, setAboutPopup] = useState<boolean>(false);
  const location = useLocation();
  let path = location.pathname;
  useEffect(() => setAboutPopup(false), [path]);
  if (size.width && size.width > 750)
    return (
      <div className="nav">
        <div className="left">
          <Link className="brandname navitem" to="/">
            EnzymeAI
          </Link>
        </div>
        <div className="right">
          {aboutPopup ? (
            <div className="aboutPopup">
              <Link to="/mission" className={`aboutItem mission ${path === "/mission" ? "active" : ""}`}>
                <img src={path === "/mission" ? missionDarkPng : missionPng} />
                <div>Our Mission</div>
              </Link>
              <Link to="/security" className={`aboutItem security ${path === "/security" ? "active" : ""}`}>
                <img src={path === "/security" ? securityDarkPng : securityPng} />
                <div>Security</div>
              </Link>
              <Link to="/tos" className={`aboutItem ${path === "/tos" ? "active" : ""}`}>
                <img src={path === "/tos" ? tosDarkPng : tosPng} />
                <div>T.O.S</div>
              </Link>
            </div>
          ) : (
            ""
          )}
          <button onClick={() => setAboutPopup(!aboutPopup)} className={`navitem ${path === "/about" ? "activeItem" : ""}`}>
            About
          </button>
          <Link className={`navitem ${path === "/faq" ? "activeItem" : ""}`} to="/faq">
            FAQs
          </Link>
          <Link className={`navitem ${path === "/blog" ? "activeItem" : ""}`} to="/blog">
            Blog
          </Link>
          <Link className={`navitem ${path === "/contact" ? "activeItem" : ""}`} to="/contact">
            Contact Us
          </Link>
          <Link className="freeoffer navitem" to={`${path}?freeOfferPopup=true`}>
            UNLOCK YOUR FREE OFFER
          </Link>
        </div>
      </div>
    );
  else
    return (
      <div className="mobileNav">
        <Link className="left" to="/">
          <img src={logo} alt="EnzymeAI" />
          <div>EnzymeAI</div>
        </Link>
        <div onClick={() => setMobileP(true)} className="right">
          <img src={bar} alt="Nav" />
        </div>
        {mobileP ? (
          <div className="popup">
            <div className="top">
              <div className="img" />
              <Link onClick={() => setMobileP(false)} className="left" to="/">
                <img src={logo} alt="EnzymeAI" />
                <div>EnzymeAI</div>
              </Link>
              <img onClick={() => setMobileP(false)} className="img" src={crossPng} />
            </div>
            <div className="links">
              <Link onClick={() => setMobileP(false)} className={`navitem ${path === "/faq" ? "activeItem" : ""}`} to="/faq">
                FAQs
              </Link>
              <Link onClick={() => setMobileP(false)} className={`navitem ${path === "/blog" ? "activeItem" : ""}`} to="/blog">
                Blog
              </Link>
              <Link onClick={() => setMobileP(false)} className={`navitem ${path === "/contact" ? "activeItem" : ""}`} to="/contact">
                Contact Us
              </Link>
              <button onClick={() => setAboutPopup(!aboutPopup)} className={`navitem ${path === "/about" ? "activeItem" : ""}`}>
                About
              </button>
            </div>
            {!aboutPopup ? (
              <>
                <Link className="freeoffer" to={`${path}?freeOfferPopup=true`}>
                  UNLOCK YOUR FREE OFFER
                </Link>
                <div className="credit">No credit card required.</div>
              </>
            ) : (
              ""
            )}
            <img src={logo} style={{ marginTop: aboutPopup ? "286px" : "36px" }} />
            {aboutPopup ? (
              <div className="aboutPopup">
                <Link
                  onClick={() => {
                    setMobileP(false);
                    setAboutPopup(false);
                  }}
                  to="/mission"
                  className={`aboutItem mission ${path === "/mission" ? "active" : ""}`}
                >
                  <img src={path === "/mission" ? missionDarkPng : missionPng} />
                  <div>Our Mission</div>
                </Link>
                <Link
                  onClick={() => {
                    setMobileP(false);
                    setAboutPopup(false);
                  }}
                  to="/security"
                  className={`aboutItem security ${path === "/security" ? "active" : ""}`}
                >
                  <img src={path === "/security" ? securityDarkPng : securityPng} />
                  <div>Security</div>
                </Link>
                <Link
                  onClick={() => {
                    setMobileP(false);
                    setAboutPopup(false);
                  }}
                  to="/tos"
                  className={`aboutItem ${path === "/tos" ? "active" : ""}`}
                >
                  <img src={path === "/tos" ? tosDarkPng : tosPng} />
                  <div>T.O.S</div>
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
};

export default Nav;
