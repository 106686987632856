import React from "react";
import "../../Css/Mission/Mission.css";
import TopSection from "./TopSection";
import Body from "./Body";
import Bottom from "./Bottom";

const Mission = () => {
  return (
    <div className="mission">
      <TopSection />
      <Body />
      <Bottom />
    </div>
  );
};

export default Mission;
