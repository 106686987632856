import React from "react";
import "../../Css/Mission/Body.css";
import Earth from "../../Assets/Mission/earth.png";
import WhiteA from "../../Assets/Mission/whiteA.png";
import WhiteB from "../../Assets/Mission/whiteB.png";
import WhiteC from "../../Assets/Mission/whiteC.png";
import Tick from "../../Assets/Mission/tick.png";

const Body = () => {
  return (
    <div className="body">
      <div className="keypoints">
        <div>
          <img src={WhiteA} alt="Protective" />
          <div>
            <div className="head">Protective</div>
            <div className="desc">Safeguarding your meals with AI, aligning each bite with your health goals.</div>
          </div>
        </div>
        <div>
          <img src={WhiteB} alt="Personalized" />
          <div>
            <div className="head">Personalized</div>
            <div className="desc">Customizing nutrition uniquely to you, ensuring every meal fits your dietary needs.</div>
          </div>
        </div>
        <div>
          <img src={WhiteC} alt="Innovative" />
          <div>
            <div className="head">Innovative</div>
            <div className="desc">Pioneering smarter, intuitive nutrition solutions for global wellness management.</div>
          </div>
        </div>
      </div>
      <img src={Earth} alt="Globe" />
      <div className="bottom">
        <div className="left">
          <div className="head">OUR CULTURE</div>
          <div className="desc">Fueling innovation, fostering collaboration, ensuring transparency, encouraging exploration, igniting passion.</div>
        </div>
        <div className="right">
          <div>
            <img src={Tick} alt="tick" />
            <div>Valuing community and partnerships.</div>
          </div>
          <div>
            <img src={Tick} alt="tick" />
            <div>Constantly advancing nutritional AI technology.</div>
          </div>
          <div>
            <img src={Tick} alt="tick" />
            <div>Swiftly embracing change and feedback.</div>
          </div>
          <div>
            <img src={Tick} alt="tick" />
            <div>User-friendly for all.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
