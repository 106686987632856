import React from "react";
import "../../Css/Security/Security.css";
import aipng from "../../Assets/Security/ai.png";
import compasspng from "../../Assets/Security/compass.png";
import dbpng from "../../Assets/Security/db.png";
import firebasepng from "../../Assets/Security/firebase.png";
import leverpng from "../../Assets/Security/lever.png";
import lockpng from "../../Assets/Security/lock.png";
import shieldpng from "../../Assets/Security/shield.png";
import { Link } from "react-router-dom";

const Security = () => {
  const lines: Array<JSX.Element> = [];
  for (let i = 0; i < 48; i++) {
    lines.push(<div key={i} className="lineB" />);
  }
  return (
    <div className="security">
      <img src={shieldpng} alt="Security" className="shield" />
      <div className="lines">
        <div className="lineA" />
        {lines}
        <div className="lineA" />
      </div>
      <div className="title">Security at EnzymeAI</div>
      <div className="desc">EnzymeAI is trusted by the best Security Experts to provide and store food data.</div>
      <Link
        style={{
          textDecorationLine: "none",
        }}
        to="/security?sendPopup=true"
        className="button"
      >
        Contact Us
      </Link>
      <div className="details">
        <div>
          <img src={leverpng} alt="Speed" />
          <div className="title">Speed Backed</div>
          <div className="desc">We are have the capability to have extremely quick and precise components whilst being encrypted.</div>
        </div>
        <div>
          <img src={lockpng} alt="Lock" />
          <div className="title">Brute Force</div>
          <div className="desc">We use cutting edge technology to make sure all entry-points to major systems and data and completely secure.</div>
        </div>
        <div>
          <img src={compasspng} alt="Compass" />
          <div className="title">Directional</div>
          <div className="desc">We make sure our systems have the easiest and quickest connection points for peak capability.</div>
        </div>
      </div>
      <div className="securityHead">Our Security Infrastructure</div>
      <div className="securityDetail">
        <div className="boxA">
          <img src={dbpng} alt="Database" />
          <div className="title">Database</div>
          <div className="desc">We use Firebase for data encryption and also employ multiple databases to train our AI models, ensuring multi-threaded encryption.</div>
        </div>
        <div className="boxB">
          <img src={firebasepng} alt="Firebase" />
          <div className="title">Firebase</div>
          <div className="desc">We use Firebase to handle much of our security infrastructure, maintaining an optimal balance between usability and security.</div>
        </div>
        <div className="boxC">
          <img src={aipng} alt="Artificial Intel." />
          <div className="title">Artificial Intel.</div>
          <div className="desc">Our AI features and algorithms include built-in AI protection and endpoints to safeguard user data and models.</div>
        </div>
      </div>
      <div className="bottom">
        <div className="bottomCont">
          <div className="left">
            <div className="title">Disclosure</div>
            <div className="desc">Get in contact with our security team if you have any noticed vulnerabilities.</div>
          </div>
          <Link to="/security?sendPopup=true" className="button">
            Get in Touch
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Security;
