import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "../../../Css/Blog/Blogs.css";
import IndividualBlog from "./IndividualBlog";
import { API_URL } from "../../../utils/contants";

export interface Props {
  loadMore: boolean;
  search: string;
  setExpand: Dispatch<SetStateAction<JSX.Element | undefined>>;
}

const Blogs = (props: Props) => {
  const [blogArray, setBlogArray] = useState<Array<JSX.Element>>();
  useEffect(() => {
    (async () => {
      let res = await fetch(`${API_URL}/blogs`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let BlogsData = (await res.json()).data;
      let tempBlogArr: Array<JSX.Element> = [];
      let maxBlogs: number = props.loadMore && props.search == "" ? BlogsData.length : 9;
      if (maxBlogs > BlogsData.length) maxBlogs = BlogsData.length;
      for (let i = 0; i < maxBlogs; i++) {
        if (BlogsData[i].title.toLowerCase().includes(props.search.toLowerCase())) {
          let elem: JSX.Element = (
            <IndividualBlog
              setExpand={props.setExpand}
              title={BlogsData[i].title}
              desc={BlogsData[i].content}
              author={BlogsData[i].author_name}
              authorUsername={BlogsData[i].author_username}
              authorImage={BlogsData[i].author_picture}
              uploadDate={BlogsData[i].uploadDate}
              key={i}
            />
          );
          tempBlogArr.push(elem);
        }
      }
      setBlogArray(tempBlogArr);
    })();
  }, [props]);

  return <div className="blogs">{blogArray}</div>;
};

export default Blogs;
