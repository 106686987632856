import React from "react";
import Faqs from "./Faqs/Faqs";
import { Link } from "react-router-dom";
import "../../Css/Faq/Body.css";

const Body = () => {
  return (
    <div className="body">
      <div className="shading s1" />
      <div className="shading s2" />
      <div className="shading s3" />
      <div className="top">
        <div className="header">Frequently Asked Questions</div>
        <div className="desc">For more information, join our Discord!</div>
      </div>
      <Faqs />
      <div className="bottom">
        <div className="header">Anything we didn't cover?</div>
        <Link className="button" to="/faq?sendPopup=true">
          SEND US A MESSAGE
        </Link>
      </div>
    </div>
  );
};

export default Body;
