import React, { Dispatch, KeyboardEvent, SetStateAction, useEffect, useState } from "react";
import Blogs from "./Blogs/Blogs";
import Search from "../../Assets/Blog/search.png";
import Close from "../../Assets/Blog/close.png";
import "../../Css/Blog/Body.css";

interface Props {
  setExpand: Dispatch<SetStateAction<JSX.Element | undefined>>;
}

const Body = (props: Props) => {
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [loadMoreText, setLoadMoreText] = useState<string>("Load More");
  const [search, setSearch] = useState<string>("");
  const [propSearch, propSetSearch] = useState<string>("");

  // DONT REMOVE (COULD BE USED IN FUTURE)
  const onLoadMore = () => {
    if (loadMore) setLoadMore(false), setLoadMoreText("Load More");
    else setLoadMore(true), setLoadMoreText("Load Less");
  };

  const onClose = () => {
    if (search !== "") setSearch("");
  };

  useEffect(() => {
    const handleDown = (e: KeyboardEvent) => {
      const targetElem = e.target as Element;
      if (!targetElem || targetElem.id !== "searchbar" || e.key !== "Enter") return;
      propSetSearch(search);
      console.log(search);
    };

    window.addEventListener("keydown", handleDown as unknown as EventListener);
    return () => window.removeEventListener("keydown", handleDown as unknown as EventListener);
  }, [search]);
  return (
    <div className="body">
      <div className="top">The latest EnzymeAI news...</div>
      <div className="search">
        <img onClick={() => propSetSearch(search)} className="search" src={Search} alt="Search" />
        <input id="searchbar" value={search} onChange={(e) => setSearch(e.target.value)} type="text" placeholder="Search for blogs" />
        <img onClick={onClose} className="close" src={Close} alt="Close" />
      </div>
      <Blogs setExpand={props.setExpand} loadMore={loadMore} search={propSearch} />
      <div className="shading" style={{ top: 143.28, left: -106.95 }} />
      <div className="shading" style={{ top: 2.34, left: 8 }} />
      <div className="shading" style={{ top: 96.68, left: 16.68 }} />
      <div className="shading" style={{ top: 27.82, right: 38.26 }} />
    </div>
  );
};

export default Body;
