import React from "react";
import SecondaryFeature from "./SecondaryFeature";
import PrimaryFeature from "./PrimaryFeature";
import icon1 from "../../../Assets/Home/featureicon1.png";
import icon2 from "../../../Assets/Home/featureicon2.png";
import icon3 from "../../../Assets/Home/featureicon3.png";

const FeaturesCollection = () => {
  return (
    <div className="featurescollection">
      <div>
        <PrimaryFeature
          color="pink"
          img={icon1}
          header="Profiles & Allergies"
          desc="Create your profile and record your food allergies, sensitivities, or preferences. The app takes care of the rest, ensuring every food/recipe can be consumed by you and your family utilizing a family account."
        />
        <PrimaryFeature
          color="blue"
          img={icon2}
          header="Advanced Food Scanning"
          desc="Instantly obtain detailed and exact nutritional data by scanning your food's barcode. With your allergy profile saved, the app also alerts you if the scanned item is safe to consume and if you can eat it based on your diet. Check if your kids/family can eat it and who in the family can!"
        />
        <PrimaryFeature
          color="green"
          img={icon3}
          header="Recipes & Sharing"
          desc="Customize and share recipes using our AI, tailored to your dietary needs and preferences. Our machine learning algorithm ensures each shared recipe has the ability to adapt for its recipient."
        />
      </div>
      <div>
        <SecondaryFeature
          color="pink"
          header="Data Protection"
          desc="Your data is safe with us. We prioritize top-tier data protection within each and EVERY one of our products"
        />
        <SecondaryFeature
          color="blue"
          header="Vast Ammounts of Data"
          desc="Millions of Food, Nutritional and Recipe data to feed our machine learning algorithms for the best result"
        />
        <SecondaryFeature
          color="green"
          header="Artificial Intelligence"
          desc="Collect data in real-time from multiple channels and move it into a data lake, in its original format."
        />
      </div>
      <div>
        <SecondaryFeature
          color="pink"
          header="Powerful Algorithms"
          desc="We utilize top-tier machine learning algorithms to provide descriptive and soundproof data for all features and security throughout the platform."
        />
        <SecondaryFeature
          color="blue"
          header="Diets"
          desc="Stay on top of your chosen diet with dynamic diet customization and instant feedback on nutrition and if a scanned item fits your diet plan."
        />
        <SecondaryFeature
          color="green"
          header="Future Features"
          desc="We have exciting features coming up, such as voice-activated recipe creation, restaurant menu access, and predictive dietary habit analysis, and much more utilizing machine learning!"
        />
      </div>
    </div>
  );
};

export default FeaturesCollection;
