import React from "react";

interface Props {
  header: string;
  desc: string;
  color: "blue" | "green" | "pink";
  img: string;
}

const PrimaryFeature = (props: Props) => {
  return (
    <div className={`primaryfeaturebox ${props.color}`}>
      <div className="container">
        <img src={props.img} alt="Feature" />
        <div className="header">{props.header}</div>
        <div className="desc">{props.desc}</div>
      </div>
    </div>
  );
};

export default PrimaryFeature;
