import React from "react";
import "../../../Css/Layout/Footer.css";
import CopyrightPng from "../../../Assets/Footer/copyright.png";
import FooterLinks from "./FooterLinks";
import LeftSection from "./LeftSection";
import RightSection from "./RightSection";
import { useWindowSize } from "@uidotdev/usehooks";
import logo from "../../../Assets/logo.png";
import mGithub from "../../../Assets/Footer/mGithub.png";
import mInstagram from "../../../Assets/Footer/mInstagram.png";
import mM from "../../../Assets/Footer/mM.png";
import mReddit from "../../../Assets/Footer/mReddit.png";
import mTelegram from "../../../Assets/Footer/mTelegram.png";
import mTwitter from "../../../Assets/Footer/mTwitter.png";
import mDiscord from "../../../Assets/Footer/mDiscord.png";
import mFacebook from "../../../Assets/Footer/mFacebook.png";
import { Link } from "react-router-dom";

const Footer = () => {
  const size = useWindowSize();
  if (size.width && size.width > 750)
    return (
      <div className="footer">
        <div className="top">
          <LeftSection />
          <FooterLinks key={1} header="Company" links={[["Blog", "/blog"]]} />
          <FooterLinks key={2} header="For Parents" links={[["Open Source", "https://github.com/EnzymeDev"]]} />
          <FooterLinks
            key={3}
            header="Support"
            links={[
              ["Contact Us", "/contact"],
              ["Security", "/security"],
            ]}
          />
          <RightSection />
        </div>
        <div className="bottom">
          <div className="brandname">EnzymeAI</div>
          <img src={CopyrightPng} alt="Copyright" />
        </div>
      </div>
    );
  else
    return (
      <div className="mobileFooter">
        <div className="top">
          <img src={logo} alt="EnzymeAI" />
          <div>EnzymeAI</div>
        </div>
        <div className="links">
          <Link target="_" to={"https://twitter.com/EnzymeAI"}>
            <img src={mTwitter} alt="Link" />
          </Link>
          <Link to={"https://www.facebook.com/profile.php?id=61559852576122"}>
            <img src={mFacebook} alt="Link" />
          </Link>
          <Link to={"https://www.instagram.com/enzymeai_mobile/"}>
            <img src={mInstagram} alt="Link" />
          </Link>
          <Link to={"https://discord.gg/VY2Xh73MkC"}>
            <img src={mDiscord} alt="Link" />
          </Link>
          <Link to={"https://github.com/EnzymeDev"}>
            <img src={mGithub} alt="Link" />
          </Link>
          <Link to={"https://www.reddit.com/r/EnzymeAI/"}>
            <img src={mReddit} alt="Link" />
          </Link>
        </div>
        <div className="bottom">© 2024 EnzymeAI. All rights reserved.</div>
        <div className="version">EnzymeAI - V1341.F</div>
      </div>
    );
};

export default Footer;
