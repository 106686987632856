import React, { useState } from "react";
import Plus from "../../../Assets/Faq/plus.png";

export interface Props {
  question: string;
  answer: string;
  lastFaq: boolean;
}

const IndividualFaq = (props: Props) => {
  const [clicked, setClicked] = useState<boolean>(false);
  const [imgStyle, setImgStyle] = useState<{ transform: "rotateZ(-45deg)" | "rotate(0deg)" }>({ transform: "rotate(0deg)" });
  const [answerStyle, setAnswerStyle] = useState({});
  const onClick = () => {
    if (!clicked) {
      setClicked(true);
      setImgStyle({ transform: "rotateZ(-45deg)" });
      setAnswerStyle({ animation: "expand .75s forwards" });
    } else {
      setClicked(false);
      setImgStyle({ transform: "rotate(0deg)" });
      setAnswerStyle({ animation: "expand2 .75s forwards" });
    }
  };
  return (
    <div className="individualFaq" style={!props.lastFaq ? { borderBottom: "1px white solid" } : {}}>
      <img onClick={onClick} style={imgStyle} src={Plus} alt="Expand" />
      <div>
        <div onClick={onClick} className="question">
          {props.question}
        </div>
        <div style={answerStyle} id="answer" className="answer">
          {props.answer}
        </div>
      </div>
    </div>
  );
};

export default IndividualFaq;
